import { Component } from '@angular/core';
import { Subscription } from 'rxjs';

import { LoaderService } from "@core/services/loader.service";
import { LoaderState } from '@core/services/loader';

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent {
  show: boolean = false;
  private subscription: Subscription;

  constructor(private loaderService: LoaderService) { }
  ngOnInit() {
    this.subscription = this.loaderService.loaderState
      .subscribe((state: LoaderState) => {
        this.show = state.show;
      });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

import { Component, OnInit } from '@angular/core';
import { LoaderService } from '@app/core/services';
import { LoaderState } from '@app/core/services/loader';
import { Subscription } from 'rxjs';

@Component({
  selector: 'score-loader',
  templateUrl: './score-loader.component.html',
  styleUrls: ['./score-loader.component.css']
})
export class ScoreLoaderComponent implements OnInit {

  show: boolean = false;
  private subscription: Subscription;

  constructor(private loaderService: LoaderService) { }
  
  ngOnInit() {
    this.subscription = this.loaderService.loaderState
      .subscribe((state: LoaderState) => {
        this.show = state.show;
      });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from "environments/environment";
import { HttpClient } from "@angular/common/http";
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private message: string;
  private baseUrl = environment.apiUrl + "/api/v1/";


  constructor(private _router: Router, private http: HttpClient) { }

  /**
  * check for expiration and if token is still existing or not
  * @return {boolean}
  */
  isAuthenticated(): boolean {
    return localStorage.getItem('token') != null && !this.isTokenExpired();
  }

  /**
 * this is used to clear anything that needs to be removed
 */
  clear(): void {
    localStorage.clear();
  }

  // simulate jwt token is valid
  isTokenExpired(): boolean {
    return false;
  }

  // loginAdmin(): void {
  //   localStorage.setItem('token', `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE1MzMyNzM5NjksImV4cCI6MTU2NDgxMDAwNSwiYXVkIjoid3d3LmV4YW1wbGUuY29tIiwic3ViIjoiVGVzdCBHdWFyZCIsIkdpdmVuTmFtZSI6IkpvaG5ueSIsIlN1cm5hbWUiOiJSb2NrZXQiLCJFbWFpbCI6Impyb2NrZXRAZXhhbXBsZS5jb20iLCJyb2xlIjoiQWRtaW4ifQ.rEkg53_IeCLzGHlmaHTEO8KF5BNfl6NEJ8w-VEq2PkE`);

  //   this._router.navigate(['/board']);
  // }

  // login(): void {
  //   localStorage.setItem('token', `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE1MzMyNzM5NjksImV4cCI6MTU2NDgxMDAwNSwiYXVkIjoid3d3LmV4YW1wbGUuY29tIiwic3ViIjoiVGVzdCBHdWFyZCIsIkdpdmVuTmFtZSI6IkpvaG5ueSIsIlN1cm5hbWUiOiJSb2NrZXQiLCJFbWFpbCI6Impyb2NrZXRAZXhhbXBsZS5jb20ifQ.GA0Y9gYIjmx1jLwuRHuBgZ8m6o-NgkD84nO0ym68CWo`);

  //   this._router.navigate(['/dashboard']);
  // }

  /**
   * this is used to clear local storage and also the route to login
   */
  logout(): void {
    this.clear();
    this._router.navigate(['/home']);
  }

  decode() {
    return JSON.parse(atob(localStorage.getItem("userData")));
  }

  // Reset Password
  emailResetCode(data){
    const url = this.baseUrl + "user/forgot-password";
    return this.http.post<any>(url, data);
  }

  checkCode(userId, code){
    const url = this.baseUrl + "user/"+ userId +"/check-verification";
    return this.http.post<any>(url, code);
  }

  resetPassword(data){
    const url = this.baseUrl + "user/reset-password";
    return this.http.post<any>(url, data);
  }

  logoutAPICalling() {
    let url = this.baseUrl + "logout"
    return this.http.get<any>(url).pipe(map((obj) => obj || {}));
  }
}

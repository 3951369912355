import { BrowserModule } from "@angular/platform-browser";
import { NgModule, ErrorHandler } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// local import
import { CoreModule } from "@core/core.module";
import { PagenotfoundComponent } from '@modules/pagenotfound/pagenotfound.component';
import { GlobalErrorHandler } from '@app/global/global-error-handler';
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SideMenuComponent } from './modules/side-menu/side-menu.component';
import { SideMenuMiniComponent } from './modules/side-menu-mini/side-menu-mini.component';
import { LoadingComponent } from './modules/loading/loading.component';
import { ScoreLoaderComponent } from "@app/modules/score-loader/score-loader.component";
import { LoaderComponent } from "@app/modules/loader/loader.component";


@NgModule({
  declarations: [
    AppComponent,
    PagenotfoundComponent,
    SideMenuComponent,
    SideMenuMiniComponent,
    LoadingComponent,
    ScoreLoaderComponent,
    LoaderComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    CoreModule,

  ],
  providers: [
    // { provide: ErrorHandler, useClass: GlobalErrorHandler },

  ],
  bootstrap: [AppComponent],
  exports: [AppRoutingModule],
})
export class AppModule { }

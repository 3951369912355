import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SideMenuMiniComponent } from '@app/modules/side-menu-mini/side-menu-mini.component';
import { SideMenuComponent } from '@app/modules/side-menu/side-menu.component';

import { PagenotfoundComponent } from "@modules/pagenotfound/pagenotfound.component";

const routes: Routes = [
  {
    path: 'alchemist-academy',
    component: SideMenuComponent,
    children: [
      {
        path: 'home',
        loadChildren: '@modules/home/home.module#HomeModule',
      },
      {
        path: 'question-bank',
        loadChildren: '@modules/questions/questions.module#QuestionsModule',
      },
      {
        path: 'manage',
        loadChildren: '@modules/manage/manage.module#ManageModule',
      },
      {
        path: 'full-mock-test',
        loadChildren: '@modules/full-mock-test/full-mock-test.module#FullMockTestModule',
      },
      {
        path: 'evaluate-question',
        loadChildren: '@app/modules/other-question-module/other-question-module.module#OtherQuestionModuleModule',
      },
      {
        path: 'myaccount',
        loadChildren: '@app/modules/myaccount/myaccount.module#MyaccountModule',
      },
      {
        path: 'ai-store',
        loadChildren: '@app/modules/ai-store/ai-store.module#AiStoreModule',
      },
      {
        path: 'training-game',
        loadChildren: '@app/modules/training-game/training-game.module#TrainingGameModule',
      },
      {
        path: 'live-classes',
        loadChildren: '@app/modules/live-class/live-class.module#LiveClassModule',
      },
    ]
  },
  {
    path: 'AI-score',
    component: SideMenuMiniComponent,
    children:[
      {
        path: 'evaluate',
        loadChildren: '@app/modules/question-evaluate/question-evaluate.module#QuestionEvaluateModule'
      },
      {
        path: 'practise',
        loadChildren: '@app/modules/practise-evaluate/practise-evaluate.module#PractiseEvaluateModule',
      },
      {
        path: 'evaluate-enter',
        loadChildren: '@app/modules/other-evaluate/other-evaluate.module#OtherEvaluateModule',
      },
      {
        path: 'training-evaluate',
        loadChildren: '@app/modules/training-evaluate/training-evaluate.module#TrainingEvaluateModule',
      },
    ]
  },
  {
    path: 'home',
    loadChildren: '@modules/mainView/main-view.module#MainViewModule',
  },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: "**",
    component: PagenotfoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers:[
  ]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let ignore =
      typeof req.body === "undefined"
      || req.body === null
      || req.body.toString() === "[object FormData]" // <-- This solves your problem
      || req.headers.has("Content-Type")
    const userToken = localStorage.getItem("token");
    const useraccesstoken = localStorage.getItem("useraccesstoken");
    if (req.url.match(/user-invoices/)) {
      const cloned = req.clone();
      return next.handle(cloned);
    }
    if (useraccesstoken!=null) {
      const cloned = req.clone({
        setHeaders: {
          'Authorization': `Bearer ${userToken}`,
          'Content-Type': 'application/json',
          'useraccesstoken': useraccesstoken
        }
      });
      localStorage.removeItem("useraccesstoken");
      return next.handle(cloned);
    }

    if (ignore) {
      const cloned = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${userToken}`)
      });
      return next.handle(cloned);
    }

    const cloned = req.clone({
      setHeaders: {
        'Authorization': `Bearer ${userToken}`,
        'Content-Type': 'application/json'
      }
    });
    return next.handle(cloned);
  }
}

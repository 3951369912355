import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/core/authentication/auth.service';
import { DetailService } from '@app/core/http/detail.service';
import { Subscription } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-side-menu-mini',
  templateUrl: './side-menu-mini.component.html',
  styleUrls: ['./side-menu-mini.component.css']
})
export class SideMenuMiniComponent implements OnInit {
  public currentRoute: any;
  public userData: any;
  private sub: Subscription;
  constructor(private _router: Router, private _auth: AuthService) { }

  ngOnInit() {
    this.currentRoute = this._router.url.split("/");
    console.log(this.currentRoute[this.currentRoute.length-1])
    let user = localStorage.getItem("userData");
    if (user) {
      this.userData = JSON.parse(atob(user));
    } else this.logout();
  }

  slideBarCollapse() {
    $('#sidebar').toggleClass('active');
    if (document.getElementById('sidebarCollapse').style.left == '0px') {
      $('#sidebarCollapse').css("left", "80px")
    } else $('#sidebarCollapse').css("left", "0px")
    // $('#sidebarCollapse').toggleClass("active");
  }


  onHover() {
    $('#sidebar ul li a > span').css("display", "block");
    $('.mini-item').css("display", "none");
    $('.expand-item').css("display", "block");
    $('#sidebarCollapse').css("left", "260px")
  }

  mouseLeave() {
    $('#sidebar ul li a > span').css("display", "none");
    $('.mini-item').css("display", "block");
    $('.expand-item').css("display", "none");
    $('#sidebarCollapse').css("left", "80px")

  }


  logout() {
    this.sub = this._auth.logoutAPICalling()
      .subscribe((res) => {
        this._auth.logout();
      })
  }
}

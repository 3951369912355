import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/core/authentication/auth.service';
import { DetailService } from '@app/core/http/detail.service';
import { Subscription } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent implements OnInit {
  public isLoading: boolean = false;
  public isShow: boolean = false;
  public userData: any;
  public packageData: any;
  public shortName: string;
  private subscription: Subscription;

  constructor(private _detailService: DetailService, private _auth: AuthService) { }

  ngOnInit() {
    let user = localStorage.getItem("userData");
    if (user) {
      this.userData = JSON.parse(atob(user));
      this.shortName = this.userData.fullName.split(" ").map(name => {
        return name.charAt(0)
      }).join("")
    } else this.logout();

  }


  slideBarCollapse() {
    $('#sidebar').toggleClass('active');
  }


  showPackage() {
    if (!this.isShow) {
      this.isLoading = true;
      let user = JSON.parse(atob(localStorage.getItem("userData")));
      this.subscription = this._detailService.getActivePackage(user.userId).subscribe((res) => {
        this.packageData = res;
        this.isShow = true;
        this.isLoading = false;
      })
    } else this.isShow = false;
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.subscription) this.subscription.unsubscribe();
  }

  logout() {
    this.subscription = this._auth.logoutAPICalling()
      .subscribe((res) => {
        this._auth.logout();
      })
  }

}

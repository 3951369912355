import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderInterceptor, AuthInterceptor, ServerErrorInterceptor } from "./interceptors/index";
import {
  AudioRecordingService,
  ErrorsService,
  LoaderService,
  LoggingService,
  NotificationService,
  ToastService,
} from "./services/index";

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    AudioRecordingService,
    ErrorsService,
    LoaderService,
    LoggingService,
    NotificationService,
    ToastService
  ],
})
export class CoreModule { }

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DetailService {

  private baseUrl = environment.apiUrl + "/api/v1/";

  constructor(private http: HttpClient) {
  }


  getDetail(detailUrl: string) {
    let url = this.baseUrl + detailUrl;
    return this.http.get<any>(url).pipe(map((obj) => obj || {}));
  }

  getAllBranch(): Observable<any> {
    return this.http.get<any>(this.baseUrl + "branchs").pipe(map((obj) => obj || []));
  }

  getdesiredScore(): Observable<any> {
    return this.http.get<any>(this.baseUrl + "desired-score").pipe(map((obj) => obj || []));
  }

  getAllPackage(): Observable<any> {
    return this.http.get<any>(this.baseUrl + "packages").pipe(map((obj) => obj || []));
  }

  getHome(): Observable<any> {
    return this.http.get<any>(this.baseUrl + "user/home/progress").pipe(map((obj) => obj || []));
  }

  getActivePackage(userId: string) {
    return this.http.get<any>(this.baseUrl + `users/${userId}/check-user-limitation`).pipe(map((obj) => obj || {}));
  }

  getPaymentHistory() {
    return this.http.get<any>(this.baseUrl + `user/payment/histories`).pipe(map((obj) => obj || {}));
  }

  getCheckProgressVocab() {
    return this.http.get<any>(this.baseUrl + `vocab/check-progress`).pipe(map((obj) => obj || {}));
  }

  getDetailVocab(qcode: string, qId: string) {
    return this.http.get<any>(this.baseUrl + `vocab/${qcode}/${qId}/detail`).pipe(map((obj) => obj || {}));
  }

  getDetailGrammar( qId: string) {
    return this.http.get<any>(this.baseUrl + `grammar/${qId}/detail`).pipe(map((obj) => obj || {}));
  }
  getEvaluateVocab(qcode: string, qId: string) {
    return this.http.get<any>(this.baseUrl + `vocab/${qcode}/${qId}/evaluate`).pipe(map((obj) => obj || {}));
  }
  getEvaluateGrammar(qId: string) {
    return this.http.get<any>(this.baseUrl + `grammar/${qId}/evaluate`).pipe(map((obj) => obj || {}));
  }

  getDetailLiveClass( liveId: string) {
    return this.http.get<any>(this.baseUrl + `liveclass/${liveId}/detail`).pipe(map((obj) => obj || {}));
  }

  getDetailYoutubeLink( linkId: string) {
    return this.http.get<any>(this.baseUrl + `dashboard/youtubelink/${linkId}`).pipe(map((obj) => obj || {}));
  }

  getInvoicePdf(url) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.get(url, { headers: headers, responseType: 'blob' });
  }


}